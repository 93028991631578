import { observable, action } from 'mobx';
import { RootStore } from './RootStore';

export default class PCLayoutStore {
  rootStore: RootStore;
  isMobile: boolean;
  @observable sideMenuCollapsed: boolean;
  @observable recordNumber: string;
  @observable headCenterText: string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.isMobile = /Mobi/.test(navigator.userAgent);
    this.sideMenuCollapsed = this.isMobile;//移动端默认折叠
    this.recordNumber = '';
    this.headCenterText = '';
  }

  @action
  setHeader(text: string) {
    this.headCenterText = text
  }

  @action
  changeCollapse() {
    this.sideMenuCollapsed = !this.sideMenuCollapsed;
    console.info(this.sideMenuCollapsed)
    if (!this.sideMenuCollapsed && !this.isMobile) {
      this.recordNumber = '粤ICP备15061904号-4'
    } else {
      this.recordNumber = ''
    }
  }
}
