import PCLayoutStore from './PCLayoutStore';
// import PCRouterStore from './PCRouterStore';
import HotelFeizhuStore from './HotelFeizhuStore';
import HotelAgodaStore from './HotelAgodaStore';
import SZParkStore from './SZParkStore';
import CompanyStore from './CompanyStore';
import ToolsStore from './ToolsStore';
import NetworkCheckStore from './NetworkCheckStore';
import ChatStore from './ChatStore';
import StockStore from './StockStore';
import WeddingStore from './WeddingStore';

export class RootStore {
  PCLayoutStore: PCLayoutStore;
  // PCRouterStore: PCRouterStore;
  HotelFeizhuStore: HotelFeizhuStore;
  HotelAgodaStore: HotelAgodaStore;
  SZParkStore: SZParkStore;
  CompanyStore: CompanyStore;
  ToolsStore: ToolsStore;
  NetworkCheckStore: NetworkCheckStore;
  ChatStore: ChatStore;
  StockStore: StockStore;
  WeddingStore: WeddingStore;

  constructor() {
    this.PCLayoutStore = new PCLayoutStore(this);
    // this.PCRouterStore = new PCRouterStore(this);
    this.HotelFeizhuStore = new HotelFeizhuStore(this);
    this.HotelAgodaStore = new HotelAgodaStore(this);
    this.SZParkStore = new SZParkStore(this);
    this.CompanyStore = new CompanyStore(this);
    this.ToolsStore = new ToolsStore(this);
    this.NetworkCheckStore = new NetworkCheckStore(this);
    this.ChatStore = new ChatStore(this);
    this.StockStore = new StockStore(this);
    this.WeddingStore = new WeddingStore(this);
  }
}

export const rootStore = new RootStore();