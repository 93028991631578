import * as React from 'react';
import { observer } from 'mobx-react';
import { Row, Col, Image } from 'antd';
import * as styles from './Wedding.module.less';
import * as dress from '../../assets/dress.json'
import LazyLoad from 'react-lazyload';


@observer
export default class RecordingLayout extends React.Component {
  componentDidMount() {
    document.title = 'Wedding Dress';
  }

  renderParts() {
    let html: any = [];
    // const pageWidth = document.documentElement.clientWidth;//屏幕宽度
    // const isMobile = /Mobi/.test(navigator.userAgent);//判断设备
    //遍历段落
    const placeholder = (<Image preview={false} src="https://quickutil.oss-cn-shenzhen.aliyuncs.com/photo/placeholder.jpg" />)
    for (let i = 0; i < dress.photos.length; i = i + 3) {
      html.push(<LazyLoad height={'33vw'} once><Row>
        <Col span={8}><Image className={styles.squre_img} src={dress.photos[i]} placeholder={placeholder}></Image></Col>
        <Col span={8}><Image className={styles.squre_img} src={dress.photos[i + 1]} placeholder={placeholder}></Image></Col>
        <Col span={8}><Image className={styles.squre_img} src={dress.photos[i + 2]} placeholder={placeholder}></Image></Col>
      </Row></LazyLoad>)
    }
    return html;
  }

  render() {
    return (
      <div className={styles.div}>
        {this.renderParts()}
      </div>
    );
  }
}