import { observable, action, runInAction } from 'mobx';
import { RootStore } from './RootStore';
import request from '../utils/request';

// 存储区
export default class SZParkStore {
    rootStore: RootStore
    @observable parkList: any = []; //公园列表
    @observable needParkArray: any = []; //需要的公园列表
    @observable phoneNumber: number; //手机号码
    @observable taskList: any = []; //任务列表

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @action
    async getParkList() {
        const response = await getParkList();
        if (response == undefined)
            return;
        runInAction(() => {
            if (response.status == 200) {
                this.parkList = response.json.message;
            }
        })
    }

    @action
    async getTaskList() {
        const response = await getTaskList()
        if (response == undefined)
            return;
        runInAction(() => {
            if (response.status == 200) {
                this.taskList = response.json.message;
            }
        })
    }

    @action
    async createTask() {
        let body: any = {};
        const phoneNumberStr = "" + this.phoneNumber;
        if (phoneNumberStr.length != 11) {
            alert("手机号为11位")
            return;
        }
        if (this.needParkArray.length == 0) {
            alert("需要勾选公园ID")
            return;
        }
        body["phoneNumber"] = "" + this.phoneNumber;
        body["needParkArray"] = this.needParkArray;
        const response = await createTask(body)
        if (response == undefined)
            return
        runInAction(() => {
            if (response.status == 200) {
                this.taskList = response.json.message;
            }
        })
    }
}

//请求区
export async function getParkList() {
    return request('/api/szpark/getParkList', {
        method: 'GET',
    })
}

export async function getTaskList() {
    return request('/api/szpark/getTaskList', {
        method: 'GET',
    })
}

export async function createTask(body: any) {
    return request('/api/szpark/createTask', {
        method: 'POST',
        body: body,
    })
}

