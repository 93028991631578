import * as React from 'react';
import { observer } from 'mobx-react';
import { Row, Col, Image } from 'antd';
import * as styles from './Wedding.module.less';
import * as recording from '../../assets/plog.json'
import LazyLoad from 'react-lazyload';


@observer
export default class RecordingLayout extends React.Component {
  componentDidMount() {
    document.title = 'Plog';
  }

  renderParts() {
    let html: any = [];
    // const pageWidth = document.documentElement.clientWidth;//屏幕宽度
    const isMobile = /Mobi/.test(navigator.userAgent);//判断设备
    //遍历段落
    for (let partIndex = 0; partIndex < recording.parts.length; partIndex++) {
      let partHtml: any = [];
      const part = recording.parts[partIndex];
      const placeholder = (<Image preview={false} src="https://quickutil.oss-cn-shenzhen.aliyuncs.com/photo/placeholder.jpg" />)
      partHtml.push(<h1 className={styles.title}>{part.title}</h1>);
      //文本
      for (const line of part.lines) {
        partHtml.push(<h3 className={styles.text}>{line}</h3>);
      }
      //移动端图像
      if (isMobile) {
        for (let i = 0; i < part.photos.length; i = i + 2) {
          partHtml.push(<LazyLoad height={'33vw'} once><Row>
            <Col span={12}><Image className={styles.img} src={part.photos[i]} placeholder={placeholder}></Image></Col>
            <Col span={12}><Image className={styles.img} src={part.photos[i + 1]} placeholder={placeholder}></Image></Col>
          </Row></LazyLoad>)
        }
      }
      //PC图像
      else {
        for (let i = 0; i < part.photos.length; i = i + 3) {
          partHtml.push(<LazyLoad height={'22vw'} once><Row>
            <Col span={8}><Image className={styles.img} src={part.photos[i]} placeholder={placeholder}></Image></Col>
            <Col span={8}><Image className={styles.img} src={part.photos[i + 1]} placeholder={placeholder}></Image></Col>
            <Col span={8}><Image className={styles.img} src={part.photos[i + 2]} placeholder={placeholder}></Image></Col>
          </Row></LazyLoad>)
        }
      }
      html.push(<div>{partHtml}</div>)
    }
    return html;
  }

  render() {
    return (
      <div className={styles.div}>
        {this.renderParts()}
      </div>
    );
  }
}