import { Layout, Menu } from 'antd';
import { Bind } from 'lodash-decorators';
import * as React from 'react';
import { Link } from 'react-router-dom';
import * as styles from './index.module.less';

interface IMenuItem {
  name: string;
  path: string;
  icon?: string;
  iconObject?: JSX.Element;
  children?: IMenuItem[];
}

interface ISideMenuProps {
  title?: string;
  collapsed?: boolean;
  menuData: IMenuItem[];
  children?: React.ReactNode;
  pathname?: string;
  logo?: string;
  onChange?: (title: string) => void;
}

interface ISideMenuState {
  openKeys: string[];
  selectedKeys: string[];
}

function renderMenu(menuData: IMenuItem[]): JSX.Element[] {
  return menuData.map((item: IMenuItem) => {
    return generateMenuItem(item);
  });
}

function generateMenuItem(menuItem: IMenuItem) {
  if (Array.isArray(menuItem.children) && menuItem.children.length) {
    return (
      <Menu.SubMenu
        key={menuItem.path}
        title={
          <span>
            {menuItem.iconObject}
            <span>{menuItem.name}</span>
          </span>
        }
      >
        {renderMenu(menuItem.children)}
      </Menu.SubMenu>
    );
  }
  return (
    <Menu.Item key={menuItem.path}>
      <Link to={menuItem.path}>
        {menuItem.iconObject}
        <span>{menuItem.name}</span>
      </Link>
    </Menu.Item>
  );
}

class SideMenu extends React.Component<ISideMenuProps, ISideMenuState> {
  rootMenuKeys = this.props.menuData.map(item => item.path);

  state = {
    openKeys: [this.rootMenuKeys[0]],
    selectedKeys: [],
  };

  componentDidMount() {
    this.refreshOpenkeys(this.props);
  }

  componentWillReceiveProps(nextProps: ISideMenuProps) {
    this.refreshOpenkeys(nextProps);
  }

  refreshOpenkeys(props: ISideMenuProps): void {
    if (props.collapsed) {
      return this.setState({ openKeys: [] });
    }
    if (props.pathname) {
      const openKeys: string[] = [];
      let currentKey = '';
      props.pathname
        .split('/')
        .filter(i => i)
        .forEach(i => {
          currentKey += `/${i}`;
          openKeys.push(currentKey);
        });

      this.setState({ openKeys: openKeys, selectedKeys: [props.pathname] });
    }
  }

  @Bind()
  handleOpenChange(openKeys: string[]) {
    this.setState({ openKeys });
  }

  @Bind()
  handleSelect(param: any) {
    this.setState({ selectedKeys: [param.key] });
    if (this.props.onChange) {
      this.props.onChange(param.key);
    }
  }

  render() {
    return (
      <Layout.Sider trigger={null} collapsible={true} collapsed={this.props.collapsed} collapsedWidth={0}>
        <div className={styles.logoContainer}>
          <img src={this.props.logo} className={styles.logo} />
          <h1>{this.props.title}</h1>
        </div>
        <Menu
          className={styles.menu}
          theme="dark"
          mode="inline"
          onOpenChange={this.handleOpenChange}
          onSelect={this.handleSelect}
          openKeys={this.state.openKeys}
          selectedKeys={this.state.selectedKeys}
        >
          {renderMenu(this.props.menuData)}
        </Menu>
        {this.props.children}
      </Layout.Sider>
    );
  }
}

export default SideMenu;
